@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=NTR&display=swap');

body {
  margin: 0;
  font-family: 'NTR', sans-serif;
  background-color: black;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
